import React, { useState } from 'react';
import { ReactComponent as ArrowUp } from '../../assets/img/arrow-up.svg';
import { ReactComponent as ArrowDown } from '../../assets/img/arrow-down.svg';
import { useTranslation } from 'react-i18next';
import { useIsTablet } from '../../hooks/useIsTablet';
import { CONTENT_DIRECTION } from '../../constants/contentDirection';
import { TraitScore } from '../../models/applications';

export interface CandidateReportProps {
  data: TraitScore[];
  name?: string;
  isLineManager?: boolean;
}

const StrengthBlock = ({
  data,
  name = '',
  isLineManager = false,
}: CandidateReportProps) => {
  const [isStrengthVisible, setIsStrengthVisible] = useState(false);
  const [isPerfomingVisible, setIsPerfomingVisible] = useState(false);

  const { t, i18n } = useTranslation();
  const isTablet = useIsTablet();

  const traits = data.map((item) => {
    let trait = {
      id: item.trait.id,
      group_id: item.trait.group_id,
      icon: item.trait.icon,
      name: '',
      display_name: '',
      title: '',
      short_description: '',
      company_definition: '',
      long_description: '',
      use_company_definition: false,
    };
    if (i18n.language === 'ar') {
      trait.name = item.trait.name_ar ? item.trait.name_ar : item.trait.name;
      trait.display_name = item.trait.display_name_ar
        ? item.trait.display_name_ar
        : item.trait.name;
      trait.title = item.trait.title_ar
        ? item.trait.title_ar
        : item.trait.title;
      trait.short_description = item.trait.short_description_ar
        ? item.trait.short_description_ar
        : item.trait.short_description;
      trait.company_definition = item.trait.company_definition_ar
        ? item.trait.company_definition_ar
        : item.trait.company_definition;
      trait.long_description = item.trait.long_description_spp_ar
        ? item.trait.long_description_spp_ar
        : item.trait.long_description_spp;
      if (isLineManager === true) {
        trait.long_description = item.trait.long_description_ar
          ? item.trait.long_description_ar
          : item.trait.long_description;
      }
    } else {
      trait.name = item.trait.name;
      trait.display_name = item.trait.display_name;
      trait.title = item.trait.title;
      trait.short_description = item.trait.short_description;
      trait.company_definition = item.trait.company_definition;
      trait.long_description = item.trait.long_description_spp;
      if (isLineManager === true) {
        trait.long_description = item.trait.long_description;
      }
    }
    trait.use_company_definition = item.trait.use_company_definition;
    return trait;
  });
  const customTraits = traits.filter((item) => item.group_id === null);

  const direction = localStorage.getItem('contentDirection');

  return (
    <section className="strength-sec candidate-report report text-align-start">
      {name && !isLineManager && (
        <h1>
          {t('Name Stand Out Strengths', {
            name,
          })}
        </h1>
      )}

      {name && isLineManager && <h1>{name}</h1>}

      <div className="strength-sec-container">
        <div className="strength-sec-column">
          <div
            className="strength-sec-title-wrapper"
            onClick={() => {
              setIsStrengthVisible(!isStrengthVisible);
            }}
          >
            <h3>{t('Significant identified strengths')}</h3>
            {isTablet && isStrengthVisible && <ArrowUp />}
            {isTablet && !isStrengthVisible && <ArrowDown />}
          </div>
          {(customTraits.length === 0 ? traits : customTraits)
            .slice(0, 5)
            .map((item) => (
              <div
                key={item.id}
                className={`identity-list-wrapper strength-sec-card ${
                  (isStrengthVisible && isTablet) || !isTablet ? '' : 'd-none'
                } ${
                  customTraits.length !== 0
                    ? 'identity-list-wrapper-custom'
                    : ''
                }`}
              >
                <div
                  className={`identity-svg ${
                    direction === CONTENT_DIRECTION.RTL ? 'rtl' : ''
                  }`}
                >
                  <img src={item.icon} alt="" />
                </div>
                <div className="identity-block-content">
                  <h4>{item.display_name}</h4>
                  <span>{item.short_description}</span>
                </div>
              </div>
            ))}
        </div>

        <div className="strength-sec-column">
          <div
            className="strength-sec-title-wrapper"
            onClick={() => {
              setIsPerfomingVisible(!isPerfomingVisible);
            }}
          >
            <h3>
              {isLineManager
                ? t('When performing at their best they')
                : t('When performing at your best you')}
            </h3>
            {isTablet && isPerfomingVisible && <ArrowUp />}
            {isTablet && !isPerfomingVisible && <ArrowDown />}
          </div>

          {(customTraits.length === 0 ? traits : customTraits)
            .slice(0, 5)
            .map((item) => (
              <div
                key={item.id}
                className={`strength-sec-card ${
                  (isPerfomingVisible && isTablet) || !isTablet ? '' : 'd-none'
                }`}
                style={
                  i18n.language === 'ar'
                    ? { alignItems: 'center' }
                    : { alignItems: 'unset' }
                }
              >
                <div
                  className="performing-best-list-wrapper"
                  dangerouslySetInnerHTML={{
                    __html: item.long_description,
                  }}
                ></div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default StrengthBlock;
