import { useTranslation } from 'react-i18next';

export const HandleNumberDisplay = (number) => {
  const { i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';

  if (number === -1) {
    return isArabic ? 'ــ' : 'N/A';
  }

  if (number !== undefined && number !== null) {
    return isArabic && number >= 0
      ? number.toLocaleString('ar-QA')
      : `${number}`;
  }

  return '0';
};

export const localizedDate = (isoDate, lang) => {
  const localVar = lang === 'ar' ? 'ar-QA' : 'en';
  const parsedDate = new Intl.DateTimeFormat(localVar, {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  }).format(isoDate);
  return parsedDate;
};
